import { supportApi } from "@/api";

export default {
    state: {
        requests: []
    },
    getters: {
        getRequests: (state) => state.requests
    },
    mutations: {
        setRequests(state, payload) {
            state.requests = payload
        }
    },
    actions: {
        request(store, payload) {
            return new Promise((resolve, reject) => {
                supportApi.request(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendRequests({ commit }, payload) {
            return new Promise((resolve, reject) => {
                supportApi.requests(payload).then((res) => {
                    commit('setRequests', res.data.data)
                    resolve(res.data)
                }).then((error) => {
                    reject(error)
                })
            })
        },
        requestGetWork(store, payload) {
            return new Promise((resolve, reject) => {
                supportApi.requestGetWork(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        requestResolve(store, payload) {
            return new Promise((resolve, reject) => {
                supportApi.requestResolve(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        }
    }
}
